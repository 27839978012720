<template>

    <div class="page-dashboard">

      <div class="py-4 mb-4 no-gradient position-relative overflow-hidden">
        <div class="container-fluid px-5">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
            <div class="flex-sm-fill">
              <h1 class="h3 font-w700 mb-0">Dashboard</h1>
  <!--          <h5 class="text-2xl mb-8 text-muted">Monitor your orders and fulfillments.</h5> -->
            </div>
          </div>
        </div>
        <!-- <Snow /> -->
      </div>

      <!-- stat boxes -->
      <div class="container-fluid stat-boxes" style="max-width: 1400px;">
        <div class="row">

          <!-- item -->
          <div class="col-md-6 col-xl-4 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
                <div class="d-flex">
                  <div class="pr-3 align-self-center">
                    <b-icon icon="exclamation-diamond" class="text-secondary" :class="{'text-danger': counts.warnings}" font-scale="3"></b-icon>
                  </div>
                  <div class="align-self-center">
                    <h5 class="text-muted">Warnings / Issues</h5>
                    <h4 class="mb-0">
                      <b-link :to="{ name: 'Jobs', query: { status: 'warnings' } }">
                        <span class="">{{ counts.warnings ? counts.warnings : '0' }}</span>
                      </b-link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- item -->
          <div class="col-md-6 col-xl-4 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
                <div class="d-flex">
                  <div class="pr-3 align-self-center">
                    <b-icon icon="droplet" class="text-secondary" font-scale="3"></b-icon>
                  </div>
                  <div class="align-self-center">
                    <h5 class="text-muted">Pending production</h5>
                    <h4 class="mb-0">
                      <b-link :to="{ name: 'Jobs', query: { status: 'incoming' } }">
                        <span class="">{{ counts.pending ? counts.pending : '0' }}</span>
                      </b-link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- item -->
          <div class="col-md-6 col-xl-4 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
                <div class="d-flex">
                  <div class="pr-3 align-self-center">
                    <b-icon icon="printer" class="text-secondary" font-scale="3"></b-icon>
                  </div>
                  <div class="align-self-center">
                    <h5 class="text-muted">In production</h5>
                    <h4 class="mb-0">
                      <b-link :to="{ name: 'Jobs', query: { status: 'in-production' } }">
                        <span class="">{{ counts.in_production ? counts.in_production : '0' }}</span>
                      </b-link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- item -->
          <!-- <div class="col-md-6 col-xl-4 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
                <div class="d-flex">
                  <div class="pr-3 align-self-center">
                    <b-icon icon="pencil-square" class="text-secondary" :class="{'text-danger': ! last_order}" font-scale="3"></b-icon>
                  </div>
                  <div class="align-self-center overflow-hidden">
                    <h5 class="text-muted">Last incoming job</h5>
                    <h4 v-if="last_order.jobid" class="mb-0 text-truncate">
                      <b-link :to="{name:'JobDetails', params: { job_id: last_order.id }}" class="">
                        {{ last_order.jobid }}
                      </b-link>
                    </h4>
                    <h5 v-else class="mb-0 text-truncate">No order</h5>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
        <!-- end: stat boxes -->

        <!-- start: CHART -->
        <div class="row">
          <div class="col-sm-12 mb-4">
            <div class="card rounded-0">
              <div class="card-body">
                <p class="text-muted small text-uppercase">Jobs per week</p>
                <ChartLine xxxwidth="null" xxxheight="null" v-if="dataCollection1" :chartData="dataCollection1" :options="chartOptions1"/>
                <b-skeleton-img variant="primary" no-aspect height="400px" v-else></b-skeleton-img>
              </div>
            </div>
          </div>
        </div>
      <!-- end: CHART -->


      <!-- INFO boxes -->
        <div class="row">

          <!-- item -->
          <!-- <div class="col-md-6 col-xl-4 mb-3">
            <div class="card h-100 rounded-0"> -->
              <!-- <div class="card-body">
                    <h4 class="text-muted pb-1">X-mas 2021 Shipping Dates</h4>
                    <p>Last shipping dates for standard delivery before Dec 24:</p>
                    <ul>
                      <li>Sweden: 17 Dec.</li>
                      <li>Europe: 15 Dec.</li>
                      <li>USA: 13 Dec.</li>
                      <li>Australia: 13 Dec.</li>
                    </ul>
                    <p>Express deliveries are more reliable and allow for some last minute deliveries (AU excluded).</p>
              </div> -->
            <!-- </div>
          </div> -->

          <!-- item -->
          <div class="col-md-6 col-xl-6 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
           
                    <h4 class="text-muted pb-1"></h4>
                    <p>
                    </p>
              </div>
            </div>
          </div>

          <!-- item -->
          <div class="col-md-6 col-xl-6 mb-3">
            <div class="card h-100 rounded-0">
              <div class="card-body">
                    <h5 class="text-muted pb-1">Your Support Team</h5>
                    <p>
                      Your production team is availabe though email, <a href="mailto:customercare@alfredssons.com">customercare@alfredssons.com</a>.
                    </p>
                    <p>
                      For technical questions and integration support, contact the <a href="mailto:support@systembyran.se">technical support team</a>.
                    </p>              
              </div>
            </div>
          </div>
          <!-- end: INFO boxes -->        
        </div>

      </div>

      <footer>
          <div class="container">
<!--

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="lead text-dark">Links</p>
              <ul class="list-unstyled">
                <li><a href="https://alfredssons.com/" target="_blank" class="">Alfredssons</a></li>
                <li><a href="https://alfredssons.com/#kontakt" target="_blank" class="">Contact</a></li>
                <li><router-link :to="{ name: 'FAQ' }" class="mt-auto">FAQ</router-link></li>
              </ul>
            </div>

            <div class="col-sm-12 offset-md-1 col-md-5">
              <p class="lead text-dark">Contact</p>
              <ul class="list-unstyled">
                <li><a href="https://www.systembyran.se" target="_blank">Systembyrån AB</a></li>
                <li><span class="">Christian Löfstedt</span></li>
                <li><b-icon icon="envelope" class="mr-1" aria-hidden="true"></b-icon><a href="mailto:christian@systembyran.se">christian@systembyran.se</a></li>
                <li><b-icon icon="telephone" class="mr-1" aria-hidden="true"></b-icon>0732-517 550</li>
              </ul>
            </div>
          </div>

          <hr class="border-muted">
-->

          <div class="row">
            <div class="col-sm-12 text-center mt-4 mb-3">
              <small>© Copyright {{ year }}, <!--<a href="https://systembyran.se/" target="_blank">-->Systembyrån AB<!--</a>--></small>
            </div>
          </div>

        </div>
      </footer>
    </div>

</template>


<script>
import ChartLine from '@/components/ChartLine.vue'


//import Snow from '@/components/Snow.vue'

export default {

  components: {
    ChartLine,
   
    //Snow,
  },
   

  data() {
    return {
      loading: true,
      error: false,
      
      // Chart Data & Options for Chart 1
      dataCollection1: false,
      chartOptions1: {
        title: {
          display: false,
          text: '',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        maintainAspectRatio: false, // loong
        responsive: true,
        aspectRatio: 4, // set with and height to null on chart & maintain to true - bug!
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              min: 0, // do not display lower than 0 on y axis (took a while to find)
              callback: function (value) { if (Number.isInteger(value)) { return value; } },
            },
          }]
        },
      },

      counts: {
        processed: 0,
        pending: 0,
        warnings: 0,
        messages: 0
      },

      last_order: [],
    }
  },

  computed: {
    year() {
      return this.$moment( new Date() ).format("YYYY");
    }
  },

  created() {
    // Set Sidebar Active Part
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'home'});
  },

  mounted () {
    this.fetchData();
  },

  methods: {
    // FETCH CURRENT ORDER - FRESH DATA
    fetchData() {
      // Reset all
      this.loading = true;

      this.$http.get(`${this.$store.state.api_base_url}/dashboard-stats`)
      .then((result) => {
        if ( result.data.status === 'ok' ) {
          this.dataCollection1 = result.data.chartdata;
          this.counts = result.data.counts;
          this.last_order = result.data.last_order;
          this.loading = false;
        }
        if ( result.data.status !== 'ok' ) {
          this.error = result.data.message || 'Could not load data!';
          this.$toastIt(this.error, {variant: 'danger', autoHideDelay: 2000});
        }
      });
    },

  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
//
// Stat Box Sizes
//
.stat-boxes svg { font-size: 500%; }
@media screen and (max-width: 762px) {
  .stat-boxes {
    .card-body { padding: 1rem; }
    //h5 { font-size: 1.25rem; }
    //h4 { font-size: 1.5rem; }
    svg  { font-size: 340%; }
  }
}

//
// Footer
//
footer {
//  margin-top: 5em;
//  padding: 2.5rem 0 1rem 0;
//  border-top: 2px dashed #bebebe;

  font-family: monospace;
  color: #636363;
  a {
    color: #636363;
  }
  a:hover {
    color: #333333;
    text-decoration: none;
  }
}
.gradient{
  background: linear-gradient(to right, #d2cbc7, #817e85);;
}

</style>